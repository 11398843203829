import moment from 'moment';

export const capitalizeAvatarName = (input: string | undefined) => {
  if (!input) return;
  const fullName = input.split(' ');
  if (fullName.length < 2) return;
  const [firstName, lastName] = fullName;
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export const concatFullName = (firstName?: string, lastName?: string) => {
  const separatedSpace = firstName ? ' ' : '';
  const fullName = (firstName ?? '') + separatedSpace + (lastName ?? '');
  return fullName;
};

export const calculateAge = (
  dob: string | null,
  format: string[] = ['YYYY-MM-DD']
) => {
  if (dob && moment(dob, format).isValid()) {
    const currentDate = moment().format('YYYY-MM-DD');
    return Math.abs(
      moment(currentDate, 'YYYY-MM-DD').diff(moment(dob, format), 'years')
    );
  }

  return '';
};

export const getAllowedCountry = () => {
  let result = process.env.REACT_APP_STAGE_ENV !== 'production' ? ['vn'] : [];
  const allowedCountries =
    process.env.REACT_APP_GOOGLE_PLACES_ALLOWED_COUNTRIES?.split(',') || [];

  result = result.concat(allowedCountries).map((item) => item.toLowerCase());

  return result;
};
