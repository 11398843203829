import { FC, useCallback, useRef } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { openToast } from 'redux/ToastSlice';
import {
  popStep,
  pushStep,
  resetCached,
  resetStep2NewPatient,
  STEPS,
  updateCached,
  updateStep3,
} from 'redux/bookingFormSlice';
import { getPatientsAPI } from 'services/APIs';
import { formatDobForBE } from 'components/BookingForm/utils/helperFunction';
import { loadOtpToken, saveOtpToken } from 'utils/storage';
import VerificationInput from 'components/VerificationInput/VerificationInput';

interface VerificationProps {
  clinicId: string;
  serviceId: string;
  appointmentDate: string;
  startTime: string;
}

const Verification: FC<VerificationProps> = ({
  clinicId,
  serviceId,
  appointmentDate,
  startTime,
}) => {
  const {
    step1: { dob: dobStep1, phoneNumber: phoneNumberStep1 },
    step2: { signUp },
    pageStack,
  } = useAppSelector((state) => state.bookingFormSlice);
  const previousPage = pageStack[pageStack.length - 2];

  const dob = previousPage === STEPS.SIGN_UP ? signUp.dob : dobStep1;

  const phoneNumber =
    previousPage === STEPS.SIGN_UP ? signUp.phoneNumber : phoneNumberStep1;

  const inputRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const handleOnBackClick = () => {
    batch(() => {
      dispatch(resetCached());
      dispatch(popStep());
    });
  };

  const handleCheckOtpSuccessful = async (otpAccessToken: string) => {
    saveOtpToken(otpAccessToken, phoneNumber);
    await handleGetPatients();
    dispatch(updateCached({ phoneNumber, dob }));
  };

  const handleGetPatients = async () => {
    if (previousPage === STEPS.SIGN_UP) {
      return batch(() => {
        dispatch(pushStep(STEPS.REVIEW));
        dispatch(
          updateCached({ phoneNumber: signUp.phoneNumber, dob: signUp.dob })
        );
      });
    }

    try {
      const formatDob = formatDobForBE(dob);

      const patients = await getPatientsAPI(
        {
          clinicId,
          serviceId,
          dob: formatDob,
          phoneNumber,
          appointmentDate,
          startTime,
        },
        {
          otpToken: loadOtpToken(phoneNumber),
        }
      );

      const nextStep = patients.length === 0 ? STEPS.NEW_PATIENT : STEPS.REVIEW;

      batch(() => {
        dispatch(resetStep2NewPatient());
        if (patients.length > 0) {
          dispatch(updateStep3({ patients }));
        }
        dispatch(pushStep(nextStep));
      });
    } catch (error) {
      displayErrorToast('Something went wrong. Please try again later');
    }
  };

  const displayErrorToast = useCallback(
    (message: string) => {
      if (!inputRef.current) return;
      dispatch(
        openToast({
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
          message: message,
        })
      );
    },
    [dispatch]
  );

  return (
    <VerificationInput
      onBackClick={handleOnBackClick}
      phoneNumber={phoneNumber}
      onCheckedOtpSuccessful={handleCheckOtpSuccessful}
    />
  );
};

export default Verification;
