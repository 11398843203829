import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import { IPractitioner } from 'interfaces/practitionerTypes';
import { Link } from 'react-router-dom';
import styles from './MobilePractitionerCard.module.scss';
import { IClinicData } from 'redux/clinicDetailsSlice';
interface MobilePractitionerCardProps {
  practitioner: IPractitioner;
  clinic: IClinicData;
}

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 10px 10px rgba(217, 217, 217, 0.5)',
    backgroundColor: '#fff',
    border: '1px solid #F9F9F9',
    borderRadius: '1rem',
    '&:not(:last-child)': {
      marginBottom: '1.6rem',
    },
    '&::before': {
      display: 'none',
    },
  },
});

const MobilePractitionerCard = (props: MobilePractitionerCardProps) => {
  const classes = useStyles();
  const {
    practitioner: { avatar, slug, name, specialty, description },
    clinic,
  } = props;

  return (
    <Accordion classes={classes}>
      <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
        <div className={styles['header-container']}>
          <CardAvatar
            avatarUrl={avatar}
            hideReviews
            totalScore={0}
            reviewCount={0}
          />
          <div className={styles['details-container']}>
            <div
              className={`${styles['practitioner-name-study']} ${styles.bold} ${styles.truncate}`}
            >
              <Link to={`/${clinic.slug}/${slug}}`}>{name}</Link>
            </div>
            <div className={`${styles['practitioner-specialty']}`}>
              <span>{specialty.map((item) => item.name).join(', ')}</span>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>{description}</AccordionDetails>
    </Accordion>
  );
};

export default MobilePractitionerCard;
