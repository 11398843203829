import { DateClickArg } from '@fullcalendar/interaction';
import axios from 'axios';
import DayCell from 'components/MonthView/DayCell/DayCell';
import MonthView from 'components/MonthView/MonthView';
import { ITimeSlot } from 'interfaces/timeslotType';
import moment from 'moment';
import { mergePractitionersAvaiBlocks } from 'pages/UpdatedSearchResultPage/utils/mergePractitionersAvaiBlocks';
import { FC, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getClinicAvailability } from 'services/APIs/getClinicAvailability';
import { getPractitionerAvailability } from 'services/APIs/getPractitionerAvailability';
import { convertToDate, getDateRange } from 'utils/dates';
import { isDateInThePast } from 'utils/isDateInThePast';
import { parseQueryUrl } from 'utils/parseQueryUrl';
import { getSlotsPer30MinutesForAvailability } from 'utils/updatedSlotsPer30Minutes';

interface MonthViewBookNowPanelProps {
  clinicId: string;
  serviceId: string;
  practitionerId: string | null;
  date: string;
  clinicTimezone: string;
  onNavigateToDayView: (dateStr: string) => void;
  slotInterval: number;
  isLoading: boolean;
  setIsLoading: (isLoaded: boolean) => void;
}

const MonthViewBookNowPanel: FC<MonthViewBookNowPanelProps> = ({
  clinicId,
  clinicTimezone,
  date,
  onNavigateToDayView,
  practitionerId,
  serviceId,
  slotInterval,
  isLoading,
  setIsLoading,
}) => {
  const location = useLocation();
  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([]);
  const [closedDates, setClosedDates] = useState<string[]>([]);
  const [isLoadedDates, setIsLoadedDates] = useState<string[]>([]);

  const { codeUrl, serviceSlugNameUrl } = parseQueryUrl(location);

  const axiosCancelTokenRef = useRef<any>(null);

  const fetchAvailabilityInDateRangeForClinic = useCallback(
    async (
      dates: string[],
      source: any
    ): Promise<{
      closedDates: string[];
      timeSlots: ITimeSlot[];
    }> => {
      const tmp = await getClinicAvailability({
        clinicId,
        serviceId,
        dates,
        timezone: clinicTimezone,
        timeBlocks: [0],
        ...(codeUrl && { code: codeUrl }),
        ...(serviceSlugNameUrl && {
          serviceSlugName: serviceSlugNameUrl,
        }),
        cancelToken: source.token,
      });

      const doctorsByClinic = tmp?.doctors || [];

      const availBlocks = mergePractitionersAvaiBlocks(
        doctorsByClinic,
        slotInterval
      );

      const closedDates = doctorsByClinic.reduce((accum: any, doctor: any) => {
        const closedDates = doctor.availableBlocks
          .filter((item: any) => item.isClosed)
          .map((item: any) => item.date);

        return accum.concat(closedDates);
      }, []);

      const timeSlots = Object.keys(availBlocks.dateItems).reduce(
        (accum: any, date: any) => {
          accum.push({
            date,
            blocks: Object.keys(availBlocks.dateItems[date]),
          });
          return accum;
        },
        []
      );

      return {
        closedDates,
        timeSlots,
      };
    },
    [
      clinicId,
      clinicTimezone,
      codeUrl,
      serviceId,
      serviceSlugNameUrl,
      slotInterval,
    ]
  );

  const fetchAvailabilityInDateRangeForPractitioner = useCallback(
    async (
      practitionerId: string,
      dates: string[],
      source: any
    ): Promise<{
      timeSlots: ITimeSlot[];
    }> => {
      const availBlocks = await getPractitionerAvailability({
        clinicId,
        serviceId,
        practitionerId,
        dates,
        timezone: clinicTimezone,
        timeBlocks: [0],
        ...(codeUrl && { code: codeUrl }),
        ...(serviceSlugNameUrl && {
          serviceSlugName: serviceSlugNameUrl,
          cancelToken: source.token,
        }),
      });

      const updatedSlotsPer30Minutes = getSlotsPer30MinutesForAvailability(
        availBlocks,
        slotInterval
      );

      return {
        timeSlots: updatedSlotsPer30Minutes,
      };
    },
    [
      clinicId,
      clinicTimezone,
      codeUrl,
      serviceId,
      serviceSlugNameUrl,
      slotInterval,
    ]
  );

  const fetchWeeklyTimeSlot = useCallback(
    (startDate: Date, endDate: Date) => {
      setIsLoading(false);

      const dateStrings = getDateRange(startDate, endDate);

      const datesInFuture = dateStrings.filter(
        (dateString) => !isDateInThePast(dateString)
      );

      if (axiosCancelTokenRef.current !== null) {
        axiosCancelTokenRef.current.cancel();
      }

      const CancelToken = axios.CancelToken;
      axiosCancelTokenRef.current = CancelToken.source();

      if (practitionerId) {
        // Call weekly API of practitioner
        setIsLoadedDates([]);

        let tmpDatesInFuture = [...datesInFuture];

        let updatedTimeSlots: ITimeSlot[] = [];

        for (let i = 0; i < datesInFuture.length / 7; i++) {
          const ranges = tmpDatesInFuture.splice(0, 7);

          const dates = [ranges[0], ranges[ranges.length - 1]];

          fetchAvailabilityInDateRangeForPractitioner(
            practitionerId,
            dates,
            axiosCancelTokenRef.current
          )
            .then(({ timeSlots }) => {
              setIsLoadedDates((state: any) => {
                return [...state, ...ranges];
              });

              updatedTimeSlots.push(...timeSlots);
            })
            .catch((error) => {});
        }

        setTimeSlots(updatedTimeSlots);
      } else {
        // Call weekly API of clinic
        setIsLoadedDates([]);

        let tmpDatesInFuture = [...datesInFuture];

        let updatedTimeSlots: ITimeSlot[] = [];

        for (let i = 0; i < datesInFuture.length / 7; i++) {
          const ranges = tmpDatesInFuture.splice(0, 7);

          const dates = [ranges[0], ranges[ranges.length - 1]];

          fetchAvailabilityInDateRangeForClinic(
            dates,
            axiosCancelTokenRef.current
          )
            .then(({ closedDates, timeSlots }) => {
              setIsLoadedDates((state: any) => {
                return [...state, ...ranges];
              });

              updatedTimeSlots.push(...timeSlots);

              setClosedDates((state: string[]) => {
                return [...state, ...closedDates];
              });
            })
            .catch(() => {});
        }

        setTimeSlots(updatedTimeSlots);
      }
    },
    [
      fetchAvailabilityInDateRangeForClinic,
      fetchAvailabilityInDateRangeForPractitioner,
      practitionerId,
      setIsLoading,
    ]
  );

  const onDateCellClicked = (arg: DateClickArg) => {
    const today = moment();
    if (moment(arg.dateStr, 'YYYY-MM-DD').isBefore(today, 'd')) return;

    if (!isLoadedDates.includes(arg.dateStr)) return;

    onNavigateToDayView(arg.dateStr);
  };

  return (
    <MonthView
      isRenderPast
      contentHeight={452}
      selectedDate={date}
      dayCellContent={(props) => {
        const date = moment(convertToDate(props.date)).format('YYYY-MM-DD');

        return (
          <DayCell
            isLoading={
              isLoading || (props.isFuture && !isLoadedDates.includes(date))
            }
            {...props}
            timeslots={timeSlots}
            {...(!practitionerId && { closedDates })}
          />
        );
      }}
      practitionerId={practitionerId}
      serviceId={serviceId}
      dateClick={onDateCellClicked}
      fetchTimeSlots={fetchWeeklyTimeSlot}
    />
  );
};

export default MonthViewBookNowPanel;
