/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useRef,
  ChangeEventHandler,
  useMemo,
} from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderOptionState,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import DentalDivider from 'components/DentalDivider/DentalDivider';
import { setSearchBarFieldsIsOpen } from 'redux/searchBarSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import CustomPopper from './CustomPopper/LocationPopper';
import useStyles from './useStyles';
import { FilterTitle } from 'interfaces/filterTypes';
import { debounce } from 'lodash';
import { setLocationOptions } from 'redux/searchBarOptionsSlice';
import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { ReactComponent as MagnifyGlassIcon } from 'assets/icons/no-result.svg';
import { ReactComponent as CurrentLocationIcon } from 'assets/icons/navigation.svg';

import styles from './LocationAutoComplete.module.scss';
import { openToast } from 'redux/ToastSlice';
import useIsMobile from 'hooks/useIsMobile';

const MAX_CHARACTERS_COUNT = 50;
interface Props {
  title: FilterTitle;
  onSelectionChanged?: (key: string, value: string | null) => void;
  setIsActive?: (isActive: boolean) => void;
  placeHolder: string;
  isResultPage?: boolean;
  isAutoSelect?: boolean;
  defaultValue?: PlaceType;
  onChangeServiceOption?: (option: { title: string }) => void;
  onOpen: () => void;
  onClose: () => void;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export type AutoCompleteComponent = React.FC<Props>;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const LocationAutoComplete: AutoCompleteComponent = (props) => {
  const isOpen = useAppSelector((state) => state.searchBarSlice);
  const dispatch = useAppDispatch();
  /* @temporarily disable current location feature
  we will bring this back in future sprints */
  const defaultOption: PlaceType = {
    description: 'Current location',
    place_id: 'current',
    structured_formatting: {} as StructuredFormatting,
  };

  const options = useAppSelector(
    (state) => state.searchBarOptionsSlice.location
  );

  const [inputValue, setInputValue] = useState(
    props.defaultValue?.description || ''
  );

  const [optionValue, setOptionValue] = useState<PlaceType | null>(() => {
    if (options.length > 0) {
      const defaultValue = options.find(
        (option) => option.description === inputValue
      );
      return defaultValue ? defaultValue : null;
    }
    return null;
  });

  const [isHightlighting, setIsHightlighting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();

  const muiStyles = useStyles();
  let inputRef = useRef<HTMLInputElement>();
  const loaded = useRef(false);
  const sessionToken = useRef('');

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            sessionToken: string;
            componentRestrictions: { country: string[] };
            types?: string[];
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        350
      ),
    []
  );

  const { onSelectionChanged = () => {}, setIsActive = () => {} } = props;

  useEffect(() => {
    if (props.title === 'location' && isOpen.location) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen.location]);

  useEffect(() => {
    if (optionValue) {
      inputRef.current?.blur();
      setInputValue(optionValue.description);
    }
  }, [optionValue]);

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      return undefined;
    } else {
      setIsActive(true);
      if (!sessionToken.current) {
        sessionToken.current = new (
          window as any
        ).google.maps.places.AutocompleteSessionToken();
      }
      if (!options.find((item) => item.description === inputValue)) {
        if (inputValue !== 'Current location') {
          setIsLoading(true);
        }
        fetch(
          {
            input: inputValue,
            sessionToken: sessionToken.current,
            componentRestrictions: {
              country:
                process.env.REACT_APP_GOOGLE_PLACES_ALLOWED_COUNTRIES?.split(
                  ','
                ) || ['CA'],
            },
          },
          (results?: readonly PlaceType[]) => {
            if (active) {
              let newOptions: PlaceType[] = [];

              if (optionValue && optionValue.place_id !== 'current') {
                newOptions = [optionValue];
              }

              if (results) {
                newOptions = [...newOptions, ...results];
              }
              dispatch(setLocationOptions(newOptions));
              setIsLoading(false);
            }
          }
        );
      }
    }

    return () => {
      active = false;
    };
  }, [inputValue]);

  const onChange = (e: any, value: PlaceType | null) => {
    setIsActive(true);
    setIsHightlighting(false);
    if (value?.place_id === 'current') {
      setOptionValue(value);
      onSelectionChanged(props.title, value.place_id);
      sessionToken.current = '';
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          () => {},
          () => {
            dispatch(
              openToast({
                position: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                message:
                  'Unable to determine current location. Please allow Firstin to access your devices location.',
              })
            );
          }
        );
      }
    } else {
      setOptionValue(value);
      if (value) {
        onSelectionChanged(props.title, value.place_id);
        sessionToken.current = '';
      } else {
        setInputValue('');
        onSelectionChanged(props.title, value);
      }
    }
  };

  const onClose = () => {
    if (optionValue) {
      setInputValue(optionValue.description);
    }
    if (props.title === 'location') {
      dispatch(
        setSearchBarFieldsIsOpen({
          location: false,
        })
      );
    }

    if (props.onClose) {
      props.onClose();
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const onFocus = () => {
      setIsActive(true);
      setInputValue('');
    };

    const onBlur = () => {
      setIsHightlighting(false);
      if (!optionValue) {
        setIsActive(false);
      }
    };

    const onKeyPress = () => {
      setIsHightlighting(true);
    };

    const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
      setInputValue(e.target.value);
    };

    return (
      <TextField
        {...params}
        inputRef={(input) => {
          inputRef.current = input;
        }}
        placeholder={optionValue?.description ?? props.placeHolder}
        variant="outlined"
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_CHARACTERS_COUNT,
          value: inputValue,
        }}
        onChange={onChangeInput}
        onKeyPress={onKeyPress}
      />
    );
  };

  const renderOption = (
    option: PlaceType,
    state: AutocompleteRenderOptionState
  ) => {
    const matches = match(option.description, inputValue);
    const parts = parse(option.description, matches);

    const renderIcon = () => {
      if (isMobile) return;
      return option.description === 'Current location' ? (
        <CurrentLocationIcon className={styles['icon']} />
      ) : (
        <LocationIcon className={styles['icon']} />
      );
    };

    return (
      <div>
        {options.indexOf(option) === 0 && (
          <DentalDivider
            orientation="horizontal"
            className={styles['exist-results-divider']}
          />
        )}
        {isHightlighting ? (
          <div className={styles['option-container']}>
            {renderIcon()}
            <span className={styles['content-container']}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  className={
                    part.highlight && isHightlighting
                      ? muiStyles.highlightText
                      : ''
                  }
                >
                  {part.text}
                </span>
              ))}
            </span>
          </div>
        ) : (
          <div className={styles['option-container']}>
            {renderIcon()}
            <span className={styles['content-container']}>
              {option.description}
            </span>
          </div>
        )}
        {options.length <= 0 && isLoading && (
          <div className={styles['flex-align-center']}>
            <CircularProgress size={20} />
          </div>
        )}
        {options.length <= 0 &&
          inputValue !== '' &&
          inputValue !== 'Current location' &&
          !isLoading && (
            <div>
              <DentalDivider
                orientation="horizontal"
                className={styles['no-results-divider']}
              />
              <div className={styles['search-messages-container']}>
                <MagnifyGlassIcon className={styles['magnify-icon']} />
                No match found
              </div>
            </div>
          )}
      </div>
    );
  };

  const renderNoOptionsText = () => {
    if (options.length <= 1 && inputValue === '') {
      return (
        <div className={styles['search-messages-container']}>
          <MagnifyGlassIcon className={styles['magnify-icon']} />
          Please type to search for a location
        </div>
      );
    }
    if (options.length <= 1 && inputValue !== '' && !isLoading) {
      return (
        <div>
          <DentalDivider
            orientation="horizontal"
            className={styles['no-results-divider']}
          />
          <div className={styles['search-messages-container']}>
            <MagnifyGlassIcon className={styles['magnify-icon']} />
            No match found
          </div>
        </div>
      );
    }
  };

  return (
    <Autocomplete
      openOnFocus
      fullWidth
      loading={isLoading}
      loadingText={
        <div className={styles['flex-align-center']}>
          <CircularProgress size={20} />
        </div>
      }
      className={muiStyles.root}
      options={options}
      filterOptions={(options) => {
        return [
          defaultOption,
          ...options.filter((item) => item.description !== 'Canada'),
        ];
      }}
      getOptionSelected={(option, value) =>
        option.description === value.description
      }
      popupIcon={<></>}
      noOptionsText={renderNoOptionsText()}
      closeIcon={null}
      onChange={onChange}
      onClose={onClose}
      value={optionValue}
      renderInput={renderInput}
      PopperComponent={CustomPopper}
      getOptionLabel={(option) => option.description}
      renderOption={renderOption}
      onOpen={props.onOpen}
    />
  );
};

export default LocationAutoComplete;
