import CardInfo from 'pages/UpdatedSearchResultPage/CardInfo/CardInfo';
import { CircularProgress } from '@material-ui/core';
import DayView from 'pages/UpdatedSearchResultPage/CardInfo/DayView/DayView';
import getPractitionerSlotPer30Minutes from 'pages/UpdatedSearchResultPage/utils/getPractitionerSlotPer30Minutes';
import { FC } from 'react';
import { IPractitionerResponse } from 'redux/practitionerSearchResultSlice';
import { useAppSelector } from 'redux/reduxHooks';
import MonthView from 'components/MonthView/MonthView';
import { DateClickArg } from '@fullcalendar/interaction';
import DayCell from 'components/MonthView/DayCell/DayCell';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import styles from './PractitionerItem.module.scss';
import {
  DentistInfo,
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from 'pages/UpdatedSearchResultPage/utils';
import getPractitionerName from 'utils/getPractitionerName';

interface PractitionerItemProps {
  practitioner: IPractitionerResponse;
  isDayView: boolean;
  navigatedDate: string;
  setSelectedPractitioner: React.Dispatch<
    React.SetStateAction<IPractitionerResponse | null>
  >;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  navigateToProfilePage: (payload: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    practitionerSlugName: string;
    clinicSlugName: string;
  }) => void;
  setSelectedSlot: React.Dispatch<
    React.SetStateAction<{
      date: string;
      timeSlot: number;
      operatoryId: string;
      practitioner: IPractitionerResponse;
      dentist: DentistInfo;
    } | null>
  >;
}

const PractitionerItem: FC<PractitionerItemProps> = ({
  practitioner,
  isDayView,
  navigatedDate,
  setSelectedPractitioner,
  navigateToProfilePage,
  setSelectedDate,
  children,
  setSelectedSlot,
}) => {
  const { status, monthlyTimeSlots } = useAppSelector(
    (state) => state.practitionerSearchResultSlice
  );

  const monthlyTimeSlot = monthlyTimeSlots.find(
    (timeSlot) => timeSlot.practitionerId === practitioner.id
  );

  const onDateCellClicked = (date: DateClickArg) => {
    setSelectedDate(date.dateStr);
    setSelectedPractitioner(practitioner);
  };

  return (
    <CardInfo
      avatar={practitioner.avatar}
      rating={practitioner.totalScore}
      reviewCount={practitioner.reviewCount}
      name={getPractitionerName(practitioner)}
      detailContent={children}
      distance={practitioner.distance}
      navigateToProfilePage={(event) => {
        navigateToProfilePage({
          event,
          practitionerSlugName: practitioner.slug,
          clinicSlugName: practitioner.clinic.slug,
        });
      }}
      specialties={practitioner.specialty.map((item) => item.name).join(' • ')}
    >
      {status === 'loading' && (
        <div className={styles['card-loading']}>
          <CircularProgress size={48} className={styles['circular-loader']} />
        </div>
      )}

      {status !== 'loading' && isDayView && (
        <DayView
          data={getPractitionerSlotPer30Minutes(practitioner.availableBlocks)}
          onClickViewMore={() => setSelectedPractitioner(practitioner)}
          onClickSlot={(date, timeSlot) => {
            const operatoryId = getOperatoryId({
              date,
              timeSlot,
              doctorId: practitioner.id,
              doctors: [
                {
                  id: practitioner.id,
                  availableBlocks: practitioner.availableBlocks,
                },
              ],
            });

            const dentist = getDentistIdForSplitScheduling({
              date,
              timeSlot,
              doctorId: practitioner.id,
              doctors: [
                {
                  id: practitioner.id,
                  availableBlocks: practitioner.availableBlocks,
                },
              ],
            });

            setSelectedSlot({
              date,
              timeSlot,
              operatoryId,
              practitioner,
              dentist,
            });
          }}
        />
      )}
      {status !== 'loading' && !isDayView && monthlyTimeSlot && (
        <>
          <MonthView
            selectedDate={navigatedDate}
            contentHeight={70 * 2 + 32}
            weekCount={2}
            isRenderPast={false}
            dayCellContent={(props) => (
              <DayCell
                {...props}
                timeslots={getPractitionerSlotPer30Minutes(
                  monthlyTimeSlot.timeSlots
                )}
              />
            )}
            dateClick={onDateCellClicked}
          />
          <div className={styles['month-view-wrapper']}>
            <DentalButton
              size="large"
              variant="outlined"
              className={styles['view-more-btn']}
              onClick={() => {
                setSelectedPractitioner(practitioner);
              }}
            >
              View more
            </DentalButton>
          </div>
        </>
      )}
    </CardInfo>
  );
};

export default PractitionerItem;
