import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

import { fontSize, toastColorsError, toastColorsSuccess } from 'utils/theme';
import { useAppSelector, useAppDispatch } from 'redux/reduxHooks';
import { closeToast } from 'redux/ToastSlice';
import useIsMobile from 'hooks/useIsMobile';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

const useStyles = makeStyles({
  root: (props: {
    type: 'success' | 'error';
    isMobile: boolean;
    isLoadedInsideIframe: boolean;
  }) => ({
    top: props.isMobile || props.isLoadedInsideIframe ? '7rem' : '5rem',
    '& .MuiSnackbarContent-root': {
      padding: '0.95rem 1.6rem',
      backgroundColor:
        props.type === 'success'
          ? toastColorsSuccess.backgroundColor
          : toastColorsError.backgroundColor,
      border: `1px solid ${
        props.type === 'success'
          ? toastColorsSuccess.borderColor
          : toastColorsError.borderColor
      }`,
      borderRadius: '1rem',
      minWidth: 'unset',
      boxShadow: 'unset',
    },
    '& .MuiSnackbarContent-message': {
      color:
        props.type === 'success'
          ? toastColorsSuccess.textColor
          : toastColorsError.textColor,
      fontSize: `${fontSize.s}`,
      fontFamily: 'Nexa',
      fontWeight: 300,
      lineHeight: '2.1rem',
    },
  }),
});

function Toast() {
  const {
    isOpen,
    message,
    position: { vertical, horizontal },
    type,
  } = useAppSelector((state) => state.toastReducer);
  const dispatch = useAppDispatch();

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const isMobile = useIsMobile();

  const classes = useStyles({ type, isMobile, isLoadedInsideIframe });

  const onToastClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeToast());
  };

  return (
    <Snackbar
      className={classes.root}
      open={isOpen}
      message={message}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={3000}
      onClose={(e, reason) => onToastClose(reason)}
      key={vertical + horizontal}
    />
  );
}

export default Toast;
