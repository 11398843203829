import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IToastState {
  isOpen: boolean;
  position: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right' | 'center';
  };
  message: string;
  type: 'success' | 'error';
}

const initialState: IToastState = {
  isOpen: false,
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
  message: '',
  type: 'error',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, action: PayloadAction<any>) => {
      state.isOpen = true;
      state.position = action.payload.position;
      state.message = action.payload.message;
      state.type = action.payload.type ?? 'error';
    },
    closeToast: () => initialState,
  },
});

export const { openToast, closeToast } = toastSlice.actions;
export default toastSlice.reducer;
