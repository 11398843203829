import React, { useRef } from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import ShowMoreText from 'react-show-more-text';

import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { ReactComponent as Dot } from 'assets/icons/dot.svg';
import { Divider } from 'components/DentalDivider';
import useIsMobile from 'hooks/useIsMobile';

import styles from './Details.module.scss';
import { IPractitioner } from 'interfaces/practitionerTypes';
import { renderRatingScore } from '../../../components/CardAvatar/CardAvatar';
import getPractitionerName from 'utils/getPractitionerName';

interface Props {
  practitioner: IPractitioner;
  hideContacts?: boolean;
  onCollapseText?: (topValue: number) => void;
  listContainerRef?: React.RefObject<HTMLDivElement>;
  pageScrollTopValueRef?: React.MutableRefObject<number>;
  isRenderInsideDetailPage: boolean;
  totalScore?: number;
  reviewCount?: number;
}

export type DetailsComponent = React.FC<Props>;

const Details: DetailsComponent = (props) => {
  const isMobile = useIsMobile();
  const nameContainerRef = useRef<HTMLDivElement>(null);
  const { practitioner, hideContacts, isRenderInsideDetailPage } = props;

  const renderName = () => {
    const { study } = practitioner;
    return (
      <div
        className={`${styles['practitioner-name-study']} ${styles.bold} ${styles.truncate}`}
        ref={nameContainerRef}
      >
        <span>{getPractitionerName(practitioner)}</span>
        <span>{study.length > 0 ? `, ${study.join(',')}` : null}</span>
      </div>
    );
  };

  const renderContact = () => {
    const { address, distance } = practitioner;
    const contactContainerClassName = cx({
      [styles['contact-container']]: true,
      [styles['hidden']]: hideContacts,
    });
    return (
      <div className={contactContainerClassName}>
        <Divider
          orientation="horizontal"
          width={4.2}
          className={styles['display-none-mobile']}
        />
        <div className={styles['flex-responsive-wrapper']}>
          <div className={`${styles['address-container']}`}>
            <LocationIcon className={styles['info-icon']} />
            <span className={`${styles['address-section']}`}>
              <span className={styles['bold']}>{distance} km</span>
              {!isMobile && ` • ` + address}
            </span>
          </div>
          <div className={`${styles['ratings-section']}`}>
            {!isNil(props.totalScore) &&
              props.reviewCount &&
              renderRatingScore(props.totalScore, props.reviewCount)}
          </div>
        </div>
      </div>
    );
  };

  const descriptionContainerClassName = cx({
    [styles['practitioner-description']]: true,
    [styles['display-none-mobile']]: true,
    [styles['show-on-mobile']]: hideContacts,
  });

  return (
    <div className={styles['details-container']}>
      {renderName()}
      <div className={`${styles['practitioner-specialty']} ${styles.truncate}`}>
        {practitioner.specialty.map((item, index) => (
          <span key={index}>
            {index !== 0 && <Dot className={styles['dot-icon']} />}
            <span>{item.name}</span>
          </span>
        ))}
        {!isRenderInsideDetailPage && (
          <>
            <Dot
              className={`${styles['dot-icon']} ${styles['dot-seperator']}`}
            />
            <span>{practitioner.clinic}</span>
          </>
        )}
      </div>
      <div className={descriptionContainerClassName}>
        <ShowMoreText
          lines={hideContacts ? 5 : 2}
          more="show more"
          less="show less"
          expanded={false}
        >
          {practitioner.description}
        </ShowMoreText>
      </div>
      {renderContact()}
    </div>
  );
};

export default Details;
