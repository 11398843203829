import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IPractitioner } from 'interfaces/practitionerTypes';
import { IPractitionerResponseData } from 'interfaces/axiosPractitionerResponseType';
import {
  ALLOWED_PRACTITIONER_STUDIES,
  MILES_IN_METER,
} from 'utils/constants/common';
import { concatFullName } from 'utils/common';

export interface IClinicService {
  id: string;
  name: string;
  duration: number;
  slug: string;
  childServices?: IClinicService[];
}

export interface IClinicData {
  id: string;
  name: string;
  description: string | null;
  avatar: string;
  services: IClinicService[];
  address: string;
  city: string;
  state: string;
  email: string;
  phone: string;
  website: string;
  workingHours: {
    day: string;
    time: string;
  }[];
  reviewCount: number;
  totalScore: number;
  timezone: string;
  slug: string;
  metaPixelCode: string | null;
  customScript: string | null;
  unitDuration: number;
  slotInterval: number;
}

export interface IReviewsState {
  ids: string[];
  data: {
    [key: string]: IReview;
  };
}

export interface IClinicPractitioners {
  ids: string[];
  data: {
    [key: string]: IPractitioner;
  };
}

interface ClinicDetailsState {
  isLoading: boolean;
  isError: boolean;
  clinicData: IClinicData;
  reviewsData: {
    isReviewsLoading: boolean;
    isError: boolean;
    totalPages: number;
    reviews: IReviewsState;
  };
  practitionersData: {
    isPractitionerLoading: boolean;
    isError: boolean;
    practitioners: IClinicPractitioners;
    totalPages: number;
    totalPractitioners: number;
  };
}

export interface IReview {
  avatar: string;
  content: string;
  reviewer: string;
  score: number;
  createdAt: string;
  source: string;
}

interface IClinicDetailsAxiosResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  avatar: string;
  photos: string[];
  workingHours: { day: string; time: string }[];
  website: string;
  address: string;
  zip: string;
  description: string;
  email: string;
  phoneNumber: string;
  latitude: number;
  longitude: number;
  reviewCount: number;
  rating: number;
  timezone: string;
  location: {
    id: string;
    createdAt: string;
    updatedAt: string | null;
    city: string;
    state: string;
    country: string;
  };
  services: IClinicService[];
  slug: string;
  metaPixelCode: string | null;
  customScript: string | null;
  unitDuration: number;
  slotInterval: number;
}

interface IClinicReviewsAxiosResponse {
  data: {
    id: string;
    createdAt: string;
    updatedAt: string | null;
    name: string;
    content: string;
    avatar: string;
    source: string;
    rating: number;
  }[];
  metadata: {
    total: number;
  };
}

interface IClinicPractitionersAxiosResponse {
  data: IPractitionerResponseData[];
  metadata: {
    total: number;
    limit: number;
    page: number;
  };
}

const REVIEWS_PER_PAGE = 5;
const PRACTITIONERS_PER_PAGE = 5;

export const getClinicDetails = async (
  slug: string,
  date: string,
  code?: string,
  serviceSlugName?: string
) => {
  const clinicData = {} as IClinicData;
  const response = await axios.get<IClinicDetailsAxiosResponse>(
    `clinics/${slug}`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        date: date,
        ...(code && { code }),
        ...(serviceSlugName && { serviceSlugName }),
      },
    }
  );
  if (response.data) {
    const { data } = response;
    clinicData.id = data.id;
    clinicData.name = data.name;
    clinicData.description = data.description;
    clinicData.avatar = data.avatar || '';
    clinicData.services = data.services.map(({ childServices, ...rest }) => ({
      ...rest,
      ...(childServices && childServices.length > 0 && { childServices }),
    }));
    clinicData.address = data.address;
    clinicData.city = data.location.city;
    clinicData.state = data.location.state;
    clinicData.email = data.email;
    clinicData.phone = data.phoneNumber;
    clinicData.website = data.website;
    clinicData.workingHours = data.workingHours;
    clinicData.reviewCount = data.reviewCount;
    clinicData.totalScore = data.rating;
    clinicData.timezone = data.timezone;
    clinicData.slug = data.slug;
    clinicData.metaPixelCode = data.metaPixelCode;
    clinicData.customScript = data.customScript;
    clinicData.unitDuration = data.unitDuration;
    clinicData.slotInterval = data.slotInterval;
  } else {
    throw Error('Not exist');
  }
  return clinicData;
};

const getClinicReviews = async (slug: string, page: number, sortBy: string) => {
  const reviewsData: IReviewsState = { ids: [], data: {} };
  let totalPages = 0;
  let sortField: 'createdAt' | 'rating';
  let sortOrder: 'asc' | 'desc';
  switch (sortBy) {
    case 'Highest rated': {
      sortField = 'rating';
      sortOrder = 'desc';
      break;
    }
    case 'Lowest rated': {
      sortField = 'rating';
      sortOrder = 'asc';
      break;
    }
    case 'Oldest': {
      sortField = 'createdAt';
      sortOrder = 'asc';
      break;
    }
    case 'Newest': {
      sortField = 'createdAt';
      sortOrder = 'desc';
      break;
    }
    default:
      sortField = 'createdAt';
      sortOrder = 'desc';
  }
  const response = await axios.get<IClinicReviewsAxiosResponse>(
    `/clinics/${slug}/reviews`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        sort: sortOrder,
        sortBy: sortField,
        page: page,
        limit: REVIEWS_PER_PAGE,
      },
    }
  );
  if (response.data.data) {
    const { data, metadata } = response.data;
    totalPages = Math.ceil(metadata.total / REVIEWS_PER_PAGE);
    data.forEach((item) => {
      reviewsData.ids.push(item.id);
      reviewsData.data[item.id] = {
        reviewer: item.name,
        avatar: item.avatar,
        content: item.content,
        score: item.rating,
        createdAt: item.createdAt,
        source: item.source,
      };
    });
  }
  return {
    totalPages: totalPages,
    reviews: reviewsData,
  };
};

export const getPaginatedPractitioners = createAsyncThunk(
  'clinicDetailsSlice/getPaginatedPractitioners',
  async ({ slug, page }: { slug: string; page?: number }) => {
    let totalPages = 0;
    let totalPractitioners = 0;
    const practitionersData: IClinicPractitioners = { ids: [], data: {} };
    const response = await axios.get<IClinicPractitionersAxiosResponse>(
      `clinics/${slug}/practitioners`,
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          sort: 'desc',
          sortBy: 'rating',
          ...(page && { limit: PRACTITIONERS_PER_PAGE, page }),
        },
      }
    );
    if (response.data.data) {
      const { data, metadata } = response.data;
      totalPractitioners = metadata.total;
      totalPages = Math.ceil(totalPractitioners / PRACTITIONERS_PER_PAGE);
      data.forEach((item) => {
        practitionersData.ids.push(item.id);
        practitionersData.data[item.id] = {
          id: item.id,
          avatar: item.avatar,
          name: concatFullName(item.firstName, item.lastName),
          practice: item.practice,
          specialty:
            item.specialties && Array.isArray(item.specialties)
              ? item.specialties
              : [],
          description: item.bio,
          workingHours: item.workingHours,
          distance: _.round(item.distance / MILES_IN_METER, 1),
          clinic: item.clinic.name,
          clinicId: item.clinic.id,
          address: item.clinic.address,
          study: item.studies
            ? item.studies
                .map((item) => item.name)
                .filter((study) => ALLOWED_PRACTITIONER_STUDIES.includes(study))
            : [],
          email: item.clinic.email,
          phone: item.clinic.phoneNumber,
          totalScore: item.rating,
          reviewCount: item.reviewCount,
          services: item.services.map((item) => ({
            id: item.id,
            name: item.name,
            clinicId: item.clinicId,
            duration: item.duration,
          })),
          slug: item.slug,
          title: item.title,
          firstName: item.firstName,
          specialist: item.specialist,
        };
      });
      return {
        totalPages: totalPages,
        practitioners: practitionersData,
        totalPractitioners: totalPractitioners,
      };
    } else {
      throw Error('Practitioners not exist');
    }
  }
);

export const getPaginatedClinicReviews = createAsyncThunk(
  'clinicDetailsSlice/getPaginatedClinicReviews',
  async ({
    slug,
    page,
    sortBy,
  }: {
    slug: string;
    page: number;
    sortBy: string;
  }) => {
    const result = await getClinicReviews(slug, page, sortBy);
    return result;
  }
);

export const getClinicBySlugName = createAsyncThunk(
  'clinicDetailsSlice/getClinicBySlugName',
  async ({
    slug,
    date,
    code,
    serviceSlugName,
  }: {
    slug: string;
    date: string;
    code?: string;
    serviceSlugName?: string;
  }) => {
    const clinicData = await getClinicDetails(
      slug,
      date,
      code,
      serviceSlugName
    );
    return {
      clinicData: clinicData,
    };
  }
);

const initialState: ClinicDetailsState = {
  isLoading: true,
  isError: false,
  clinicData: {} as IClinicData,
  reviewsData: {
    reviews: {
      ids: [],
      data: {},
    },
    isError: false,
    isReviewsLoading: true,
    totalPages: 0,
  },
  practitionersData: {
    isError: false,
    isPractitionerLoading: true,
    totalPages: 0,
    totalPractitioners: 0,
    practitioners: {
      ids: [],
      data: {},
    },
  },
};

const clinicDetailsSlice = createSlice({
  name: 'clinicDetailsSlice',
  initialState,
  reducers: {
    clearClinicDetails: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClinicBySlugName.fulfilled, (state, action) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        clinicData: {
          ...state.clinicData,
          ...action.payload.clinicData,
        },
      };
    });
    builder.addCase(getClinicBySlugName.rejected, (state, action) => {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    });
    builder.addCase(getPaginatedClinicReviews.fulfilled, (state, action) => {
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          isReviewsLoading: false,
          ...action.payload,
        },
      };
    });
    builder.addCase(getPaginatedClinicReviews.pending, (state, action) => {
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          isReviewsLoading: true,
        },
      };
    });
    builder.addCase(getPaginatedPractitioners.fulfilled, (state, action) => {
      return {
        ...state,
        practitionersData: {
          ...state.practitionersData,
          isPractitionerLoading: false,
          ...action.payload,
        },
      };
    });
  },
});

export const { clearClinicDetails } = clinicDetailsSlice.actions;
export default clinicDetailsSlice.reducer;
