import { FC, useState } from 'react';
import { useAppSelector } from 'redux/reduxHooks';
import BookNowPanel, {
  IClinicServicesOption,
} from '../BookNowPanel/BookNowPanel';
import PoweredBySection from 'components/PoweredBySection/PoweredBySection';
import { IClinicBookingData } from '../ClinicDetailsPage';
import BookingForm from 'components/BookingForm/BookingForm';
import { addToCartTracking } from 'utils/tracking/metaEventTracking';
import HeaderSectionIframe from './HeaderSectionIframe/HeaderSectionIframe';
import styles from './Iframe.module.scss';

interface IframeProps {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  clinicServicesOptions: IClinicServicesOption[];
}

const Iframe: FC<IframeProps> = ({ date, setDate, clinicServicesOptions }) => {
  const [clinicBookingData, setClinicBookingData] =
    useState<IClinicBookingData | null>(null);

  const { clinicData, isLoading } = useAppSelector(
    (state) => state.clinicDetailsSlice
  );

  const handleBookClinicAppointment = (data: IClinicBookingData) => {
    addToCartTracking(clinicData.metaPixelCode);
    setClinicBookingData(data);
  };

  const getServiceName = () => {
    if (!clinicBookingData) return;
    const service = clinicData.services.find(
      (item) =>
        item.id === clinicBookingData.serviceId ||
        item.childServices?.find(
          (service) => service.id === clinicBookingData.serviceId
        )
    );
    return service?.name;
  };

  const serviceName = getServiceName();

  if (isLoading) return null;

  if (clinicBookingData && serviceName) {
    return (
      <div>
        <HeaderSectionIframe clinicData={clinicData} />

        <BookingForm
          date={clinicBookingData.appointmentDate}
          timeBlock={clinicBookingData.timeSlot}
          serviceId={clinicBookingData.serviceId}
          clinicId={clinicData.id}
          practitionerId={clinicBookingData.practitioner.id}
          operatoryId={clinicBookingData.operatoryId}
          dentist={clinicBookingData.dentist}
          bookingSummaryInfo={{
            avatar: clinicData.avatar,
            title: clinicData.name,
            clinicName: clinicData.name,
            clinicAddress: clinicData.address,
            clinicEmail: clinicData.email,
            clinicPhoneNumber: clinicData.phone,
            serviceTitle: `${serviceName}${
              clinicBookingData.practitioner.isChosen
                ? ` • ${clinicBookingData.practitioner.title}`
                : ''
            }`,
          }}
          closeBookingDialog={() => setClinicBookingData(null)}
          chairOptimizations={clinicBookingData.chairOptimizations}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderSectionIframe clinicData={clinicData} />
      <BookNowPanel
        clinicData={clinicData}
        clinicServicesOptions={clinicServicesOptions}
        onClickClinicTimeSlot={handleBookClinicAppointment}
        onClickPractitionerTimeSlot={handleBookClinicAppointment}
        date={date}
        setDate={setDate}
      />
      <PoweredBySection />
    </div>
  );
};

export default Iframe;
