import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as PractitionerIcon } from 'assets/icons/practitioners.svg';
import { ReactComponent as PracticeProfileIcon } from 'assets/icons/practice-profile.svg';
import { ReactComponent as LocationsIcon } from 'assets/icons/locations.svg';

import styles from '../Info.module.scss';
import { useAppSelector } from 'redux/reduxHooks';
import { Specialty } from 'interfaces/specialty';
interface PractitionerInfoProps {
  specialty: Specialty[];
  practiceName: string;
  distance: number;
  isInsideAvailabilityModal?: boolean;
}

const PractitionerInfo: FC<PractitionerInfoProps> = ({
  specialty,
  distance,
  practiceName,
  isInsideAvailabilityModal,
}) => {
  const {
    location: { name },
  } = useAppSelector((state) => state.filterSlice);

  const container = cx({
    [styles['container']]: true,
    [styles['container-availability']]: isInsideAvailabilityModal,
  });

  return (
    <div className={container}>
      <div className={styles.row}>
        <PractitionerIcon className={styles['icon']} />
        <span className={styles['text']}>
          {specialty.map((item) => item.name).join(', ')}
        </span>
      </div>
      <div className={styles.row}>
        <PracticeProfileIcon className={styles['icon']} />
        <span className={styles['text']}>{practiceName}</span>
      </div>
      <div className={styles.row}>
        <LocationsIcon className={styles['icon']} />
        <div className={`${styles['text']} ${styles['bold']}`}>
          {distance}km • <span className={styles['font-normal']}>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default PractitionerInfo;
