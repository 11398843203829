import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import useStyles from './useStyles';

interface Props extends ButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

export type DentalButtonComponent = React.FC<Props>;

const DentalButton: DentalButtonComponent = (props) => {
  const classes = useStyles();

  const onClick = (event: any) => {
    if (!!props.isLoading) {
      return;
    }

    props.onClick?.(event);
  };

  return (
    <Button
      disabled={props.disabled}
      type={props.type || 'button'}
      className={`${classes.button} ${props.className}`}
      variant={props.variant}
      disableFocusRipple
      disableElevation
      onClick={onClick}
    >
      {props.isLoading ? (
        <CircularProgress
          className={
            props.variant === 'outlined' ? '' : classes.circularProgress
          }
          size={20}
        />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default DentalButton;
