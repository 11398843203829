import React, { FC, useState } from 'react';
import cx from 'classnames';
import LoginOptionDialog from 'components/LoginOptionDialog/LoginOptionDialog';

import styles from './SignInForm.module.scss';
import { useForm } from 'react-hook-form';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import NavigationBtnControl from 'components/BookingForm/NavigationBtnControl/NavigationBtnControl';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { pushStep, resetBookingForm, STEPS } from 'redux/bookingFormSlice';
import {
  AUTH_SYS_TYPE,
  LOADING_STATUS,
  PROVIDER_ID,
} from 'utils/constants/common';
import GoogleButton from 'components/BookingForm/SocialButton/GoogleButton';
import FacebookButton from 'components/BookingForm/SocialButton/FacebookButton';
import { useHistory } from 'react-router-dom';
import { loginSocialThunk, loginThunk, updateSysType } from 'redux/authSlice';
import { openToast } from 'redux/ToastSlice';
import { UserCredential } from 'firebase/auth';
import { checkEmail, resendEmailVerificationLink } from 'services/APIs';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { saveSocialUserData } from 'utils/storage';
import FormTextInput from 'components/BookingForm/FormTextInput/FormTextInput';
import {
  getUserDataFromFacebook,
  getUserDataFromGoogle,
  getUserEmailFromSocial,
} from 'components/BookingForm/utils/getOAuthData';
import { ROUTES } from 'routes/config';
import { HelperText } from 'components/Form/HelperText/HelperText';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface SignInFormProps {
  isInBookingFlow?: boolean;
}

const SignInForm: FC<SignInFormProps> = ({ isInBookingFlow = false }) => {
  const {
    authSlice: { status },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { control, formState, getValues } = useForm({
    mode: 'all',
  });

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isEmailNotVerified, setIsEmailNotVerified] = useState(false);

  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const handleClickOnBack = () => {
    dispatch(resetBookingForm());
  };

  const checkLoginPageNavigation = () => {
    if (isInBookingFlow) return;
    return history.goBack();
  };

  const onLogin = async (e: any) => {
    e.preventDefault();

    const email = getValues('email');
    const password = getValues('password');

    const data = await dispatch(loginThunk({ email, password }));

    if (data.type === 'authSlice/login/fulfilled') {
      return checkLoginPageNavigation();
    }

    if (data.payload === 'Unverified') return setIsEmailNotVerified(true);
    dispatch(
      openToast({
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
        message: data.payload,
      })
    );
  };

  const handleOnNavigateEmail = () => {
    setIsDialogOpened(false);
    handleOnNavigateSignUpPage();
  };

  const handleOnNavigateSignUpPage = () => {
    if (!isInBookingFlow) return history.push(ROUTES.SIGN_UP_PAGE);

    dispatch(pushStep(STEPS.SIGN_UP));
  };

  const handleSocialLogin = async (
    userCredential: UserCredential,
    sysType: string
  ) => {
    const { user } = userCredential;
    try {
      setIsLoading(true);
      const userToken = await user.getIdToken();

      const providerId =
        sysType === AUTH_SYS_TYPE.GOOGLE
          ? PROVIDER_ID.GOOGLE
          : PROVIDER_ID.FACEBOOK;

      const email = getUserEmailFromSocial(userCredential, providerId);

      const { data } = await checkEmail(email);

      if (data?.isExistingEmail) {
        await dispatch(
          loginSocialThunk({
            email,
            sysType,
            userToken,
          })
        );
        return isInBookingFlow ? null : history.goBack();
      }

      const userData =
        sysType === AUTH_SYS_TYPE.GOOGLE
          ? await getUserDataFromGoogle(userCredential)
          : await getUserDataFromFacebook(userCredential);

      const socialData = { ...userData, userToken };

      dispatch(
        updateSysType({
          socialData,
          sysType,
        })
      );

      setIsLoading(false);

      if (!isInBookingFlow) {
        saveSocialUserData({ socialData, sysType });
        return history.push(ROUTES.SIGN_UP_PAGE);
      }

      dispatch(pushStep(STEPS.SIGN_UP));
    } catch (error) {
      dispatch(
        openToast({
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
          message: 'Something went wrong. Please try again later',
        })
      );
      setIsLoading(false);
    }
  };

  const handleClickOnForgotPassword = () => {
    if (isInBookingFlow) {
      dispatch(pushStep(STEPS.FORGET_PASSWORD));
    } else {
      history.push(ROUTES.FORGOT_PASSWORD_PAGE);
    }
  };

  const handleResendVerifiedEmail = async () => {
    const isResendSuccessfully = await resendEmailVerificationLink(
      getValues('email')
    );

    dispatch(
      openToast({
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
        message: isResendSuccessfully
          ? 'Email has been sent successfully'
          : 'Something went wrong. Please try again later',
        type: isResendSuccessfully ? 'success' : 'error',
      })
    );

    setIsResendDisabled(true);
  };

  const displayExtraErrorMessage = () => {
    if (!isEmailNotVerified) return;
    return (
      <div className={styles['error-container']}>
        <HelperText error={'Unverified email.'} />
        <button
          type="button"
          className={styles['resend-button']}
          onClick={handleResendVerifiedEmail}
          disabled={isResendDisabled}
        >
          Resend verification email
        </button>
      </div>
    );
  };

  return (
    <form className={containerClassName} noValidate onSubmit={onLogin}>
      <LoginOptionDialog
        isOpen={isDialogOpened}
        setIsOpen={setIsDialogOpened}
        isInBookingFlow={isInBookingFlow}
        onNavigateEmail={handleOnNavigateEmail}
        onNavigateSignUpPage={handleOnNavigateSignUpPage}
      />
      {isLoading && <LoadingScreen />}
      <div className={styles['otp-form']}>
        <div className={`${styles['bold']} ${styles['title']}`}>Sign in</div>
        <div className={styles['section']}>
          <div>Sign in via social media</div>
          <div className={styles['social-section']}>
            <GoogleButton onSuccess={handleSocialLogin} />
            <FacebookButton onSuccess={handleSocialLogin} />
          </div>
          <div className={styles['fill-section']}>or by email</div>
        </div>
        <div className={styles['form-section']}>
          <FormTextInput
            autoComplete="email"
            type="text"
            name="email"
            label="Email address"
            control={control}
            placeholder="Input your email"
            required="This field is required"
            extraErrorMessage={displayExtraErrorMessage()}
            rules={{
              onChange: (event: any) => {
                const value = event.target.value;
                if (value && isEmailNotVerified) {
                  setIsEmailNotVerified(false);
                  setIsResendDisabled(false);
                }
              },
            }}
          />
          <FormTextInput
            type="password"
            name="password"
            label="Password"
            control={control}
            placeholder="Input your password"
            required="This field is required"
          />
        </div>
        <div className={styles['footer-section']}>
          <DentalButton
            disabled={!formState.isValid || isEmailNotVerified}
            variant="contained"
            isLoading={status === LOADING_STATUS.LOADING}
            onClick={onLogin}
            type="submit"
          >
            Sign in
          </DentalButton>
          <input type="submit" hidden />
          <button
            type="button"
            className={styles.link}
            onClick={handleClickOnForgotPassword}
          >
            Forgot password?
          </button>
          <span>
            Don’t have account yet?{' '}
            <button
              type="button"
              className={styles.popup}
              onClick={() => setIsDialogOpened(true)}
            >
              Sign up
            </button>
          </span>
        </div>
      </div>
      {isInBookingFlow && <NavigationBtnControl onBack={handleClickOnBack} />}
    </form>
  );
};

export default SignInForm;
