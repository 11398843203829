import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './ToastSlice';
import filterSlice from './filterSlice';
import practitionerSlice from './practitionerSlice';
import practitionerDetailsSlice from './practitionerDetailsSlice';
import clinicDetailsSlice from './clinicDetailsSlice';
import mapSlice from './mapSlice';
import searchBarSlice from './searchBarSlice';
import searchBarOptionsSlice from './searchBarOptionsSlice';
import authSlice from './authSlice';
import showMapMobileSlice from './showMapMobileSlice';
import bookingFormSlice from './bookingFormSlice';
import clinicsSearchResultsSliceV2 from './clinicsSearchResultsSliceV2';
import practitionerSearchResultSlice from './practitionerSearchResultSlice';
import mapSlice2 from './mapSliceV2';
import searchResultFilterSlice from './searchResultFilterSlice';
import familyBookingSlice from './familyBookingSlice';

const store = configureStore({
  reducer: {
    mapSlice,
    filterSlice,
    toastReducer,
    searchBarSlice,
    practitionerSlice,
    clinicDetailsSlice,
    searchBarOptionsSlice,
    practitionerDetailsSlice,
    authSlice,
    showMapMobileSlice,
    bookingFormSlice,
    // New Redux
    clinicsSearchResultsSliceV2,
    practitionerSearchResultSlice,
    mapSlice2,
    searchResultFilterSlice,
    familyBookingSlice,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
