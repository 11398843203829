import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import * as Scroll from 'react-scroll';

import useIsMobile from 'hooks/useIsMobile';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import PasswordGuidance, {
  VALIDATIONS,
} from 'components/PasswordGuidance/PasswordGuidance';

import {
  AUTH_SYS_TYPE,
  LOADING_STATUS,
  MOBILE_OS,
} from 'utils/constants/common';
import { getMobileOS } from 'utils/getMobileOS';

import styles from './SignUpForm.module.scss';
import moment from 'moment';
import LoginOptionDialog from 'components/LoginOptionDialog/LoginOptionDialog';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import NavigationBtnControl from 'components/BookingForm/NavigationBtnControl/NavigationBtnControl';
import {
  popStep,
  pushStep,
  resetStep2SignUp,
  SignUp,
  STEPS,
  updateStep2,
} from 'redux/bookingFormSlice';
import { batch } from 'react-redux';
import { resetSysType, updateSysType } from 'redux/authSlice';
import { useHistory } from 'react-router-dom';
import { loadSocialUserData, removeSocialUserData } from 'utils/storage';
import FormTextInput from 'components/BookingForm/FormTextInput/FormTextInput';
import { ROUTES } from 'routes/config';
import PhoneInput from 'components/BookingForm/FormTextInput/PhoneInput/PhoneInput';
import EmailInput from 'components/BookingForm/FormTextInput/EmailInput/EmailInput';
import { geCountryByPhoneNumber } from 'utils/formatPhoneNumber';
import { firstNameValidation, lastNameValidation } from 'utils/inputValidation';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import MobileDatePicker from 'components/BookingForm/Step1/MobileDatePicker/MobileDatePicker';

interface SignUpFormProps {
  isInBookingFlow?: boolean;
  onSignUpSuccessful?: () => void;
  resetForm: () => void;
}

const SignUpForm: FC<SignUpFormProps> = ({
  isInBookingFlow = false,
  resetForm,
  onSignUpSuccessful,
}) => {
  const {
    authSlice: { sysType, status, socialData },
    bookingFormSlice: {
      step2: { signUp },
      cachedData,
    },
  } = useAppSelector((state) => state);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const wrapperClassName = cx({
    [styles['wrapper-iframe']]: isLoadedInsideIframe,
  });

  const dispatch = useAppDispatch();

  const getDefaultValue = () => {
    if (signUp.email) return { ...signUp, confirmPassword: signUp.password };
    if (socialData) {
      return {
        ...socialData,
        dob: socialData.dob ?? '',
        phoneNumber: socialData.phoneNumber ?? '',
      };
    }
    const cachedSocialData = loadSocialUserData();
    if (cachedSocialData?.socialData) {
      resetForm();
      return dispatch(
        updateSysType({
          socialData: cachedSocialData.socialData,
          sysType: cachedSocialData.sysType,
        })
      );
    }
  };

  const {
    control,
    formState,
    getValues,
    watch,
    trigger,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: getDefaultValue(),
  });

  const isMobile = useIsMobile();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const history = useHistory();

  const footerContainer = cx({
    [styles['footer']]: true,
    [styles['mt-29']]: isInBookingFlow,
  });

  const onScrollToElement = (elementName: string) => {
    if (!isMobile) return;
    const extraHeight = -80;

    let headerHeight = -80;

    const mobileOS = getMobileOS();

    if (mobileOS === MOBILE_OS.ANDROID || mobileOS === MOBILE_OS.OTHER) {
      headerHeight =
        -(document.getElementById('main-header')?.offsetHeight || 0) +
        extraHeight;
    }

    Scroll.scroller.scrollTo(elementName, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: headerHeight,
    });
  };

  const handleClickOnBack = () => {
    dispatch(resetSysType());
    if (!isInBookingFlow) return history.push(ROUTES.SIGN_IN_PAGE);

    batch(() => {
      dispatch(popStep());
      dispatch(resetStep2SignUp());
    });
  };

  const getFormData = () => {
    const email = getValues('email');
    const firstName = getValues('firstName');
    const lastName = getValues('lastName');
    const dob = getValues('dob');
    const phoneNumber = getValues('phoneNumber');
    const password = getValues('password');

    const formData = {
      email,
      firstName,
      lastName,
      dob,
      phoneNumber,
      countryCode: signUp.countryCode || geCountryByPhoneNumber(phoneNumber),
      password,
    };
    return formData;
  };

  const handleClickOnNext = () => {
    const signUp = {
      ...getFormData(),
      email: getFormData().email.trim(),
      firstName: getFormData().firstName.trim(),
      lastName: getFormData().lastName.trim(),
    };

    const nextStep =
      signUp.phoneNumber === cachedData.phoneNumber
        ? STEPS.REVIEW
        : STEPS.VERIFICATION;

    batch(() => {
      dispatch(updateStep2({ signUp: signUp }));
      dispatch(pushStep(nextStep));
    });
  };

  const handleOnSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const signUp = getFormData();

    dispatch(updateStep2({ signUp }));

    onSignUpSuccessful?.();
  };

  const handleChangeAuth = () => {
    if (sysType === AUTH_SYS_TYPE.PASSWORD) return setIsDialogOpen(true);
    batch(() => {
      dispatch(resetSysType());
      dispatch(resetStep2SignUp());
    });
    removeSocialUserData();
    resetForm();
  };

  return (
    <>
      <MobileDatePicker
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUpdated={(newValue) => {
          setValue('dob', `${newValue.month}/${newValue.day}/${newValue.year}`);
          clearErrors('dob');
        }}
        {...(getValues('dob') && {
          day: moment(getValues('dob')).format('DD'),
          month: moment(getValues('dob')).format('MM'),
          year: moment(getValues('dob')).format('YYYY'),
        })}
      />
      <div className={wrapperClassName}>
        <form className={containerClassName} noValidate>
          <h3 className={styles['header']}>Just a few details</h3>
          <div className={styles['body']}>
            <div>
              <EmailInput
                control={control}
                trigger={trigger}
                isEmailChecking={isEmailChecking}
                setIsEmailChecking={setIsEmailChecking}
              />
              <div className={styles['body-group']}>
                <FormTextInput
                  autoComplete="given-name"
                  type="text"
                  name="firstName"
                  label="First Name"
                  control={control}
                  placeholder="Input your first name"
                  required="This field is required"
                  rules={{
                    validate: (value: string) => {
                      const message = firstNameValidation(value);
                      return message || true;
                    },
                  }}
                />
                <FormTextInput
                  autoComplete="family-name"
                  name="lastName"
                  label="Last Name"
                  control={control}
                  placeholder="Input your last name"
                  required="This field is required"
                  rules={{
                    validate: (value: string) => {
                      const message = lastNameValidation(value);
                      return message || true;
                    },
                  }}
                />
              </div>
              <div className={styles['body-group']}>
                <div onClick={() => onScrollToElement('sign-up-form-dob')}>
                  <Scroll.Element name="sign-up-form-dob">
                    <FormTextInput
                      autoComplete="bday"
                      type="date"
                      name="dob"
                      label="Date of Birth"
                      control={control}
                      placeholder="MM/DD/YYYY"
                      mask="99/99/9999"
                      required="This field is required"
                      rules={{
                        validate: (value: string) => {
                          if (!moment(value, 'MM/DD/YYYY', true).isValid()) {
                            return 'Invalid date of birth format';
                          }
                        },
                      }}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </Scroll.Element>
                </div>
                <PhoneInput
                  control={control}
                  phoneNumber={signUp.phoneNumber}
                  countryCode={signUp.countryCode}
                  onChangeCountryCode={(countryCode, phoneNumber) => {
                    dispatch(
                      updateStep2({
                        signUp: {
                          countryCode,
                          phoneNumber,
                        } as SignUp,
                      })
                    );
                  }}
                />
              </div>
              {sysType === AUTH_SYS_TYPE.PASSWORD && (
                <>
                  <div className={styles['body-group']}>
                    <FormTextInput
                      type="password"
                      name="password"
                      label="Password"
                      control={control}
                      placeholder="Input your password"
                      required="This field is required"
                      rules={{
                        validate: (value: string) => {
                          const isPasswordValid = VALIDATIONS.every(
                            ({ pattern }) => pattern.test(value)
                          );
                          return isPasswordValid;
                        },
                      }}
                    />
                    <div className={styles['display-none-on-desktop']}>
                      <PasswordGuidance currentPassword={watch('password')} />
                    </div>
                    <FormTextInput
                      type="password"
                      name="confirmPassword"
                      label="Confirm password"
                      control={control}
                      placeholder="Re-enter password"
                      required="This field is required"
                      rules={{
                        validate: (value: string) => {
                          const password = getValues('password');

                          if (value !== password) {
                            return 'Passwords do not match';
                          }
                        },
                      }}
                    />
                  </div>
                  <div className={styles['display-none-on-mobile']}>
                    <PasswordGuidance currentPassword={watch('password')} />
                  </div>
                </>
              )}
              {!isInBookingFlow && isMobile && (
                <div className={styles['submit-button-on-sign-up-page']}>
                  <DentalButton
                    className={styles['footer-button']}
                    disabled={!formState.isValid || isEmailChecking}
                    variant="contained"
                    onClick={handleOnSubmit}
                    type="submit"
                    isLoading={status === LOADING_STATUS.LOADING}
                  >
                    Create account
                  </DentalButton>
                </div>
              )}
            </div>

            <div className={footerContainer}>
              {!isInBookingFlow && !isMobile && (
                <DentalButton
                  className={styles['footer-button']}
                  disabled={!formState.isValid || isEmailChecking}
                  variant="contained"
                  onClick={handleOnSubmit}
                  type="submit"
                  isLoading={status === LOADING_STATUS.LOADING}
                >
                  Create account
                </DentalButton>
              )}
              <div className={styles['option-container']}>
                Already have account yet?{' '}
                <button
                  type="button"
                  className={styles['navigate-button']}
                  onClick={handleClickOnBack}
                >
                  Sign in
                </button>
              </div>
              <button
                type="button"
                className={`${styles['navigate-button']} ${styles['mt-8']}`}
                onClick={handleChangeAuth}
              >
                {sysType === AUTH_SYS_TYPE.PASSWORD
                  ? 'or sign up by social account'
                  : 'or sign up with email address'}
              </button>
            </div>
          </div>

          <LoginOptionDialog
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
            isInBookingFlow={isInBookingFlow}
            resetForm={resetForm}
          />
        </form>
        {isInBookingFlow && (
          <NavigationBtnControl
            onBack={handleClickOnBack}
            onNext={handleClickOnNext}
            isDisabledNextBtn={!formState.isValid || isEmailChecking}
          />
        )}
      </div>
    </>
  );
};

export default SignUpForm;
