import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import cx from 'classnames';
import styles from './Step1.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import {
  pushStep,
  resetBookingForm,
  resetStep2NewPatient,
  STEPS,
  updateCached,
  updateStep1,
  updateStep3,
} from 'redux/bookingFormSlice';
import NavigationBtnControl from '../NavigationBtnControl/NavigationBtnControl';
import { batch } from 'react-redux';
import FormTextInput from '../FormTextInput/FormTextInput';
import { checkEmail, getPatientsAPI } from 'services/APIs';
import { formatDobForBE } from '../utils/helperFunction';
import PhoneInput from '../FormTextInput/PhoneInput/PhoneInput';
import { loadOtpToken } from 'utils/storage';
import GoogleButton from '../SocialButton/GoogleButton';
import FacebookButton from '../SocialButton/FacebookButton';
import { ReactComponent as EmailIcon } from 'assets/icons/email_icon.svg';
import { UserCredential } from 'firebase/auth';
import { AUTH_SYS_TYPE, PROVIDER_ID } from 'utils/constants/common';
import {
  getUserDataFromFacebook,
  getUserDataFromGoogle,
  getUserEmailFromSocial,
} from '../utils/getOAuthData';
import { loginSocialThunk, updateSysType } from 'redux/authSlice';
import { openToast } from 'redux/ToastSlice';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import MobileDatePicker from './MobileDatePicker/MobileDatePicker';

interface Step1Props {
  onBack: () => void;
  clinicId: string;
  serviceId: string;
  appointmentDate: string;
  startTime: string;
}

const Step1: FC<Step1Props> = ({
  onBack,
  clinicId,
  serviceId,
  appointmentDate,
  startTime,
}) => {
  const {
    step1,
    step2: { newPatient },
    cachedData,
  } = useAppSelector((state) => state.bookingFormSlice);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const headerSectionClassName = cx({
    [styles['headerSection']]: true,
    [styles['headerSection-iframe']]: isLoadedInsideIframe,
  });

  const bodySectionClassName = cx({
    [styles['bodySection']]: true,
    [styles['bodySection-iframe']]: isLoadedInsideIframe,
  });

  const footerSectionClassName = cx({
    [styles['footerSection']]: true,
    [styles['footerSection-iframe']]: isLoadedInsideIframe,
  });

  const { control, formState, getValues, setValue } = useForm({
    mode: 'all',
    defaultValues: step1,
  });

  const getPatients = async (phoneNumber: string, dob: string) => {
    try {
      setIsLoading(true);
      const patients = await getPatientsAPI(
        {
          clinicId,
          serviceId,
          dob: formatDobForBE(dob),
          phoneNumber: phoneNumber,
          appointmentDate,
          startTime,
        },
        { otpToken: loadOtpToken(phoneNumber) }
      );
      setIsLoading(false);
      return patients;
    } catch (error) {
      throw error;
    }
  };

  const handleClickOnBack = () => {
    onBack();
  };

  const getNextStepForCachedData = async (phoneNumber: string, dob: string) => {
    let nextPage = STEPS.VERIFICATION;

    try {
      const patients = await getPatients(phoneNumber, dob);
      if (patients.length === 0) {
        nextPage = STEPS.NEW_PATIENT;
      } else {
        dispatch(updateStep3({ patients }));
        nextPage = STEPS.REVIEW;
      }
      dispatch(updateCached({ phoneNumber, dob }));
      dispatch(resetStep2NewPatient());
    } catch (error) {
      if (error === 'Unauthorized') {
        nextPage = STEPS.VERIFICATION;
      }
    }

    return nextPage;
  };

  const handleClickOnNext = async () => {
    const phoneNumber = getValues('phoneNumber');
    const dob = getValues('dob');

    let nextPage = STEPS.VERIFICATION;

    if (cachedData.dob === dob && cachedData.phoneNumber === phoneNumber) {
      nextPage = STEPS.REVIEW;
    }

    if (cachedData.dob !== dob && cachedData.phoneNumber === phoneNumber) {
      nextPage = await getNextStepForCachedData(phoneNumber, dob);
    }

    if (
      cachedData.dob === dob &&
      cachedData.phoneNumber === phoneNumber &&
      newPatient.isFromPatientInfo
    ) {
      nextPage = STEPS.NEW_PATIENT;
    }

    // Cached from localstorage
    if (
      loadOtpToken(phoneNumber) &&
      !cachedData.dob &&
      !cachedData.phoneNumber
    ) {
      nextPage = await getNextStepForCachedData(phoneNumber, dob);
    }

    batch(() => {
      dispatch(pushStep(nextPage));
      dispatch(updateStep1({ phoneNumber, dob }));
    });
  };

  const handleClickSignIn = () => {
    batch(() => {
      dispatch(resetBookingForm());
      dispatch(pushStep(STEPS.SIGN_IN));
    });
  };

  const handleSocialLogin = async (
    userCredential: UserCredential,
    sysType: string
  ) => {
    const { user } = userCredential;
    try {
      setIsLoading(true);
      const userToken = await user.getIdToken();

      const providerId =
        sysType === AUTH_SYS_TYPE.GOOGLE
          ? PROVIDER_ID.GOOGLE
          : PROVIDER_ID.FACEBOOK;

      const email = getUserEmailFromSocial(userCredential, providerId);

      const { data } = await checkEmail(email);

      if (data?.isExistingEmail) {
        return await dispatch(
          loginSocialThunk({
            email,
            sysType,
            userToken,
          })
        );
      }

      const userData =
        sysType === AUTH_SYS_TYPE.GOOGLE
          ? await getUserDataFromGoogle(userCredential)
          : await getUserDataFromFacebook(userCredential);

      const socialData = { ...userData, userToken };

      setIsLoading(false);

      batch(() => {
        dispatch(
          updateSysType({
            socialData,
            sysType,
          })
        );

        dispatch(pushStep(STEPS.SIGN_UP));
      });
    } catch (error) {
      dispatch(
        openToast({
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
          message: 'Something went wrong. Please try again later',
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <MobileDatePicker
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUpdated={(newValue) => {
          setValue(
            'dob',
            `${newValue.month}/${newValue.day}/${newValue.year}`,
            { shouldValidate: true }
          );
        }}
      />
      <form noValidate className={containerClassName}>
        <div className={styles['patient-form-container']}>
          <div className={headerSectionClassName}>
            <div className={styles['title']}>Let's get started!</div>
          </div>
          <div className={bodySectionClassName}>
            <FormTextInput
              type="date"
              name="dob"
              label="Date of Birth"
              control={control}
              placeholder="MM/DD/YYYY"
              mask="99/99/9999"
              required="This field is required"
              rules={{
                validate: (value: string) => {
                  if (!moment(value, 'MM/DD/YYYY', true).isValid()) {
                    return 'Invalid date of birth format';
                  }
                },
              }}
              onClick={() => setIsModalOpen(true)}
            />
            <PhoneInput
              control={control}
              countryCode={step1.countryCode}
              phoneNumber={step1.phoneNumber}
              onChangeCountryCode={(countryCode, phoneNumber) => {
                dispatch(
                  updateStep1({
                    countryCode,
                    phoneNumber,
                  })
                );
              }}
            />
            <div className={footerSectionClassName}>
              <div>Already have an account? Sign in to save time</div>
              <div className={styles['social-section']}>
                <GoogleButton onSuccess={handleSocialLogin} />
                <FacebookButton onSuccess={handleSocialLogin} />
                <button
                  className={styles['click-sign-in']}
                  onClick={handleClickSignIn}
                >
                  <EmailIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <NavigationBtnControl
          onBack={handleClickOnBack}
          onNext={handleClickOnNext}
          isDisabledNextBtn={!formState.isValid}
          isLoading={isLoading}
        />
      </form>
    </>
  );
};

export default Step1;
